// Dependencies
///////////////////////////////////////////////
///////////////////////////////////////////////
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper, { Navigation, Pagination } from 'swiper';

// Utils
///////////////////////////////////////////////
///////////////////////////////////////////////
import * as utils from './utils/utils';
import Router from './utils/router';

// Layout
///////////////////////////////////////////////
///////////////////////////////////////////////
import layout from './layout/layout'
import header from './layout/header';
import footer from './layout/footer';


// Pages
///////////////////////////////////////////////
///////////////////////////////////////////////
import homepage from './pages/homepage';

// Blocks
///////////////////////////////////////////////
///////////////////////////////////////////////
import b01Slider from './blocks/b01_slider'

// Routing
///////////////////////////////////////////////
///////////////////////////////////////////////
const routes = new Router([
    {
        'file': layout,
        'dependencies': [utils]
    },
    {
        'file': header,
        'dependencies': [utils],
        'resolve': '.js-header'
    },
    {
        'file': footer,
        'dependencies': [utils],
        'resolve': '.js-footer'
    },
    {
        'file': homepage,
        'dependencies': [utils],
        'resolve': '.js-hompage'
    },
    {
        'file': b01Slider,
        'dependencies': [Swiper, Pagination],
        'resolve': '.js-slider'
    }
]);

window.addEventListener('DOMContentLoaded', () => routes.load())