export default {
	init: (Swiper, Pagination) => {

        // Slider
        ////////////////////////////////////////////////
        const sliderEls = document.querySelectorAll('.js-slider')

        if (sliderEls.length) {
            sliderEls.forEach(slider => {
                
                const sliderInstance = new Swiper(slider, {
                    slidesPerView: 1,
                    modules: [Pagination],
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                })

            })
        }

	}
}