/**
 * @function setCSSVariables 
 * @param {HTMLElement} the element which we want to set css vars
 * @param {String} varsName : the name of css vars
 * @param {String} value : the value of css vars 
 */
export function setCSSVariables(varsName, value, element = false){
    let targetElement = element ? element : document.documentElement;
    targetElement.style.setProperty(varsName, value);
}

/**
 * @function
 * @name outerHeight Get height of element with margin top and bottom
 * @param {HTMLElement} the element
 */
export function outerHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
  
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}

/**
 * @function
 * @name dispachEvent dispatch event on element
 * @param {HTMLElement} the element
 * @param {String} the event name
 * @param {Object} the optional event details
 */
export function dispachEvent(element, eventName, datas = {}){
    const event = new CustomEvent(eventName, { detail : datas });

    element.dispatchEvent(event);
}

/**
 * @function
 * @name setCookie
 * @param {String} name :the cookie name
 * @param {String} value : the cookie value
 * @param {Int} daysToExpire : the expiration day number
 */
export function setCookie(name, value, daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

    const cookieString = `${name}=${value || ''}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
}

/**
 * @function
 * @name getCookie
 * @param {String} name :the cookie name
 */
export function getCookie(name) {
    const cookieName = `${name}=`;
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }

    return null;
}