export default {
	init: (utils) => {

		// Set CSS Variables
        /////////////////////////////////////////////////////////////////////////////////
		const header = document.querySelector('.js-header');

		if (header) {
			// utils.setCSSVariables('--header-height', `${header.offsetHeight}px`)
        	// window.addEventListener('resize', () => utils.setCSSVariables('--header-height', `${header.offsetHeight}px`))
		}


		const $body = document.querySelector('body');
		const $toggleMobileNav = document.querySelector('.js-toggle-mobileNav');
		const $mobileNav = document.querySelector('.js-mobileNav');
		const $mobileNav__backdrop = document.querySelector('.js-mobileNav__backdrop');
		
		const closeMobileNav = ()=>{
			$body.classList.remove('disable-scroll');
			$mobileNav.classList.remove('active');
			$toggleMobileNav.classList.remove('active');
			$mobileNav__backdrop.classList.remove('active');
		}
		const openMobileNav = ()=>{
			$body.classList.add('disable-scroll');
			$mobileNav.classList.add('active');
			$toggleMobileNav.classList.add('active');
			$mobileNav__backdrop.classList.add('active');
		}

		$mobileNav__backdrop.addEventListener('click', () => {
			closeMobileNav();
		})

		$toggleMobileNav.addEventListener('click', () => {
			if($toggleMobileNav.classList.contains('active')){
				closeMobileNav();
			}else{
				openMobileNav();
			}
		})
	},

}